import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import servicesImg from '../img/Services.png';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import Accordion from '../components/Accordion/Accordion';

class AboutPageTemplate extends React.Component {
    accordionData = [{
      title: 'What is machine learning?',
      content: `Machine learning is an approach to programming where instead of telling the computer exactly what to do, you show it examples of what you want it to do and allow it to learn. Instead of instructing a computer to do using code, you use a combination of code and data to teach the computer how to behave.
      <br/>
      <br/>
      Machine learning allows you to use computers to do things like understanding language, recognising images or spotting patterns in massive datasets. Machine learning can be used to help computers make better decisions and make predictions about the future. It can be used to automate tasks done by humans or to enhance and go beyond human abilities.
      `,
    },
    {
      title: 'What is artificial intelligence?',
      content: 'Artificial Intelligence (AI) can refer to any system that behaves intelligently. What we mean by intelligent is a <a href="https://plato.stanford.edu/entries/artificial-intelligence/#WhatExacAI">big question</a>. Often people take it to mean something that displays human or superhuman levels of intelligence. What is meant by this is somewhat vague. ',
    },
    {
      title: 'Is machine learning the same as artificial intelligence?',
      content: 'Kind of. We have found that the best way to achieve artificial intelligence is by using machine learning, which is why the two terms have become largely synonymous. However, you can get AI systems that do not use machine learning (such as rule based systems) or machine learning systems that are not particularly intelligent. ',
    },
    {
      title: 'What is data science?',
      content: `Data science is a term that evolved over the last decade to refer to a range of activities that involve understanding and getting value from data. It draws from fields like computer science and statistics. Often data scientists use machine learning to get the most value from data.
      `,
    },
    {
      title: 'What is your process?',
      content: `We are fairly flexible in how we work. Typically we will have an initial meeting to understand your business and requirements. If it turns out you have a need for the services we provide we will scope out a project. 
      <br/>
      <br/>
      Scoping out the project typically begins with exploratory data analysis and developing a detailed understanding of your business. You are the expert on this so we work closely with you during this phase.
      <br/>
      <br/>
      We can work with any sort of system and will build products that integrate fully with your existing infrastructure.
      <br/>
      <br/>
      Any intellectual property generated during our engagement will be your property. We don’t try to sell licences or push you down specific supplier routes unless it is going to be to your advantage.`,
    },
    {
      title: 'How do you charge?',
      content: `Typically we will charge a fixed fee for a project. We work with you to make sure that you are clear on what you need and what you will be getting. We recognise that many of the smaller companies we work with do not have huge budgets and we take this into account in providing the most competitive, predictable pricing we can.
      <br/>
      <br/>
      Alternatively, if you wish to have an ongoing relationship or have ad hoc requirements we can work on a day rate or retainer basis.
      `,
    },
    {
      title: 'Can you give me some examples?',
      content: `Have a look at our <a href="https://www.ortom.ai/case-studies">case studies</a>
      `,
    },
    {
      title: 'How can I find out more?',
      content: `<a href="https://www.ortom.ai/#footer">Get in touch.</a>
      `,
    },
    {
      title: 'What if I just want some advice?',
      content: `That's fine, <a href="https://www.ortom.ai/#footer">get in touch.</a> and we can have a chat.
      `,
    },
    ];

    render() {
      return (
        <div>
          <div className="container content mobile-container">
            <div className="columns">
              <div className="column is-2">
                <p>
                  What we do
                </p>
              </div>
              <div className="column is-10">
                <h2>Developing machine learning and data products for your business needs.</h2>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12">
                {servicesImg ? (
                  <div className="featured-thumbnail" style={{ textAlign: 'center' }}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: servicesImg,
                        alt: 'Services photo of city',
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="small-gap" />

            <div className="column is-8 is-offset-2">
              <p>
                We help companies use machine learning in their business.
                We usually work with small technology firms, startups and scaleups,
                but it can be anybody who wants to use data to improve their organisation.
                These are the types of the services we provide:
              </p>
            </div>

            <div className="column is-10 is-offset-2">
              <h3>Custom Data Product Development</h3>
            </div>

            <div className="column is-7 is-offset-3">
              <p>
                Data products are software systems where some or all
                of the functionality is derived from data.
                Often these products use machine learning.
                Building machine learning based systems can be difficult and
                differs significantly from ordinary software development.
                It requires specialist knowledge of maths and statistics,
                machine learning and MLops.
                Ortom has extensive experience designing,
                building and deploying machine learning based data products.
              </p>

              <p>
                We can help with the entire development lifecycle:
                from R&amp;D and data exploration, through to deploying reliable,
                maintainable, scalable production systems. If you have an idea,
                Ortom can help make it a reality.
              </p>

              <p>
                We will work closely with you to work out exactly what you need.
                This usually involves an assessment of available data and a thorough
                understanding of your business requirements.
                We will never bamboozle you with technical jargon or show off
                about the methods we know. The solution that
                we design and build will be bespoke to your needs.
              </p>

              <p>
                Most machine learning projects fail when they are not fully
                aligned to business requirements.
                At Ortom we have repeatedly built commercially successful products,
                taking into account all of the imperfections and idiosyncrasies of your data.
                By working with us you have the best chance of building a
                data driven product that allows your company to grow.
              </p>
            </div>

            <div className="small-gap" />

            <div className="column is-10 is-offset-2">
              <h3>AI and Data strategy</h3>
            </div>

            <div className="column is-7 is-offset-3">
              <p>
                If you are just getting started with AI, ML and data science,
                it can be daunting. It’s hard to know if you are going in the right direction
                or wasting your time. It’s important to assess where you are
                and what AI can do for you, before you waste a lot of time and
                money. We can look at your organisation and assess current
                capabilities. We can do this on behalf of potential investors or
                to help founders.
              </p>
              <p>
                The types of question we can help with include:
              </p>
              <ul>
                <li>How can we use AI/ML/ Data science? </li>
                <li>What is possible? </li>
                <li>What will the challenges be? </li>
                <li>Should we build our own solution or buy a product?</li>
                <li>What should we do first? </li>
                <li>How long will it take? </li>
                <li>What resources will  we need? </li>
                <li>Do we have the right data and skills and technology?  </li>
              </ul>
            </div>

            <div className="small-gap" />

            <div className="column is-10 is-offset-2">
              <h3>Recruitment, training and mentoring</h3>
            </div>

            <div className="column is-7 is-offset-3">
              <p>
                You want to build a data science team but don’t know where to start.
                You have some candidates but need some help interviewing them.
                We can help.
              </p>
              <p>
                Data science is a young field and experienced staff are scarce.
                Often a better option than hiring is to train up existing staff or
                hire and train juniors. This requires some investment in training
                and development.
              </p>
            </div>

            <div className="small-gap" />

          </div>

          <div className="container content mobile-container">
            <div className="accordion">
              {this.accordionData.map(({ title, content }, index) => (
                <Accordion title={title} content={content} key={index} />
              ))}
            </div>
          </div>

          <div className="gap" />
        </div>
      );
    }
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
